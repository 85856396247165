import { Container, Flex, Text } from '@mantine/core';
import {
  FaFacebook,
  FaYoutube,
  FaTiktok,
  FaInstagram,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import classes from './Footer.module.css';

import Logo from '../../assets/logo.svg';

export default () => (
    <footer>
      <Container size="xl" className={classes.footer} h={{ md: '325px' }}>
        <Flex direction={{ base: 'column', md: 'row' }} className={classes.inner} gap="20px">
          <img src={Logo} alt="They will kill you" />
          <Flex direction="column" gap={5}>
            <Text className={classes.followText} visibleFrom="md">Follow Us</Text>
            <Flex gap="md">
              <Link to="https://www.facebook.com/theywillkillyoufb" target="_blank">
                <FaFacebook color="white" size="32px" />
              </Link>
              <Link to="https://www.youtube.com/@theywillkillyou" target="_blank">
                <FaYoutube color="white" size="32px" />
              </Link>
              <Link to="https://www.tiktok.com/@theywillkillyou_tt" target="_blank">
                <FaTiktok color="white" size="32px" />
              </Link>
              <Link to="https://www.instagram.com/theywillkillyou" target="_blank">
                <FaInstagram color="white" size="32px" />
              </Link>
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </footer>
  );
