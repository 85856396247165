import { Container, Flex, Group, Skeleton, Space, Stack, Text } from '@mantine/core';

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import classes from './ProfileContent.module.css';
import VerticalAd from '@/components/Ads/VerticalAd';
import { Profile } from '@/types';

import RichTextViewer from '@/components/RichTextViewer/RichTextViewer';
import Carousel from '@/components/Carousel/Carousel';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default ({ data, isLoading }: { data?: Profile, isLoading: boolean }) => (
    <Container size="xl" mt="xl" p={{ base: '20px', md: 0 }}>
      <ScrollToTop />
      <Flex direction="row" justify="space-between">
        {isLoading || !data ? <Skeleton w="940px" /> : (
          <Stack maw="940px" style={{ flex: 10 }}>
            <Text className={classes.sectionTitle}>
              bio
            </Text>
            <div className={classes.sectionContent}>
              <RichTextViewer richText={data.bio} />
            </div>

            <Text className={classes.sectionTitle}>
              murder story
            </Text>
            <div className={classes.sectionContent}>
              <RichTextViewer richText={data.murderStory} />
            </div>

            <Text className={classes.sectionTitle}>
              media
            </Text>

            <Carousel
              name={data.name}
              slides={data?.media}
            />
          </Stack>
        )}
        <Flex direction="column" justify="flex-start" style={{ flex: 1 }} visibleFrom="md">
          <VerticalAd />
          <Space h="xl" />
          <VerticalAd />
        </Flex>
      </Flex>
    </Container>
  );
