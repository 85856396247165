import { useParams } from 'react-router-dom';
import { Space } from '@mantine/core';
import { useQuery } from 'react-query';
import { Helmet } from 'react-helmet';
import Header from '@/components/Header/Header';
import HorizontalAd from '@/components/Ads/HorizontalAd';
import Footer from '@/components/Footer/Footer';
import ProfileSummary from '@/components/ProfileSummary/ProfileSummary';
import Featured from '@/components/Featured/Featured';
import ProfileContent from '@/components/ProfileContent/ProfileContent';
import { getProfile } from '@/queries';
import { JsonLdSchema } from '@/types';
import NotFoundPage from '@/pages/Error.page';

export default () => {
  const { id } = useParams();
  const { data, isLoading, isError } = useQuery(['getProfile', { id }], getProfile, {
      retry: false
  });

  if (isError) {
    return <NotFoundPage />;
  }

  const jsonLd: JsonLdSchema = {
    "@context": "https://schema.org",
    "@type": "Person",
    identifier: data?.shortUrl || '',
    name: data?.markup?.name || '',
    alternateName: data?.markup?.alternateName || null,
    nationality: {
      "@type": "Country",
      name: typeof data?.markup?.nationality === 'string'
        ? data?.markup?.nationality
        : 'Unknown',
    },
    description: data?.markup?.description || '',
    knowsAbout: Array.isArray(data?.markup?.knowsAbout)
    ? data.markup.knowsAbout
    : [],
    subjectOf: {
      "@type": "Thing",
      name: typeof data?.markup?.subjectOf === 'object' && data?.markup?.subjectOf?.name
        ? data?.markup?.subjectOf?.name
        : 'Unknown',
    },
    numberOfVictims: data?.markup?.numberOfVictims || 'Unknown',
    image: data?.markup?.image || [],
  };

  return (
    <>
      <Helmet>
        <title>{isLoading ? 'Loading...' : `They Will Kill You | ${data?.type} ${data?.name}`}</title>
        <script type="application/ld+json">
          {JSON.stringify(jsonLd)}
        </script>
        <meta name="description" content={jsonLd.description} />
      </Helmet>
      <Header />
      <ProfileSummary isLoading={isLoading} data={data} />
      <HorizontalAd />
      <ProfileContent isLoading={isLoading} data={data} />
      <HorizontalAd />
      <Featured title="Related Profiles" />
      <Space h="xl" />
      <Footer />
    </>
  );
};
