import {Container, Grid, Group, Image, SimpleGrid, Skeleton, Stack, Text} from '@mantine/core';
import dateFormat from 'dateformat';
import moment from 'moment';
import classes from './ProfileSummary.module.css';
import { Profile } from '@/types';

export default ({ data, isLoading }: { data?: Profile, isLoading: boolean }) => {
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (!data) {
    return <div>No data available</div>;
  }
  const formattedBirthDate = moment.utc(data.birth).format('MMMM Do, YYYY');
  const formattedDeathDate = data.death ? moment.utc(data.death).format('MMMM Do, YYYY') : null;
  return (
    <>
      <Container size="xl" mt={{ base: 0, md: 'xl' }} p={0}>
        {isLoading || !data ? <Skeleton w="100%" h="331px" /> : (
          <>
            <Stack hiddenFrom="md">
              {data.media[0] ? <Image src={data.media[0]} alt={data.name} style={{ margin: 0 }} /> : <Skeleton h="100%" w="100%" />}
              <Stack style={{ backgroundColor: 'black', color: 'white', position: 'relative', top: '-40px', width: 'calc(100% - 40px)' }} p="20px" m="0 20px">
                  <Text>{dateFormat(data.birth, 'yyyy')} - {dateFormat(data.death, 'yyyy')}</Text>
                  <Text className={classes.name}>{data.name}</Text>
              </Stack>
              <Stack style={{ backgroundColor: '#EEE' }}>
                <Text className={classes.title}>Summary</Text>
                <div className={classes.dataColumn}>
                  <div className={classes.dataContainer}><Text>Name: </Text><b>{data.name}</b></div>
                  <div className={classes.dataContainer}><Text>Nickname: </Text><b>{data.nickname}</b></div>
                  <div className={classes.dataContainer}><Text>Years Active: </Text><b>{data.date}</b></div>
                  <div className={classes.dataContainer}><Text>Birth: </Text><b>{formattedBirthDate}</b></div>
                  <div className={classes.dataContainer}><Text>Status: </Text><b>{data.status}</b></div>
                  <div className={classes.dataContainer}><Text>Class: </Text><b>{data.type}</b></div>
                  <div className={classes.dataContainer}><Text>Victims: </Text><b>{data.victims}</b></div>
                  <div className={classes.dataContainer}><Text>Method: </Text><b>{data.method}</b></div>
                  {data.death ? <div className={classes.dataContainer}><Text>Death: </Text><b>{formattedDeathDate}</b></div> : null}
                  <div className={classes.dataContainer}><Text>Nationality: </Text><b>{data.nationality}</b></div>
                  </div>
              </Stack>
            </Stack>

            <Grid visibleFrom="md" maw="inherit">
              <Grid.Col span={4} style={{ padding: 0 }}>
                {data.media[0] ? <Image src={data.media[0]} alt={data.name} /> : <Skeleton h="100%" w="100%" />}
              </Grid.Col>
              <Grid.Col span={8}>
                <Stack>
                  <Grid>
                    <Grid.Col span={6} style={{ backgroundColor: 'black', color: 'white' }}>
                      <Text>{dateFormat(data.birth, 'yyyy')}{data.death ? - dateFormat(data.death, 'yyyy') : ''}</Text>
                      <Text className={classes.name}>{data.name}</Text>
                    </Grid.Col>
                  </Grid>
                  <Grid>
                    <Grid.Col span={12} style={{ backgroundColor: '#EEE' }}>
                      <Text className={classes.title}>Summary: {data.type}</Text>
                      <SimpleGrid cols={2}>
                        <div className={classes.dataColumn}>
                          <div className={classes.dataContainer}><Text>Name: </Text><b>{data.name}</b></div>
                          {data.nickname ? <div className={classes.dataContainer}><Text>Nickname: </Text><b>{data.nickname}</b></div> : null}
                          <div className={classes.dataContainer}><Text>Status: </Text><b>{data.status}</b></div>
                          <div className={classes.dataContainer}><Text>Victims: </Text><b>{data.victims}</b></div>
                          <div className={classes.dataContainer}><Text>Method: </Text><b>{data.method}</b></div>
                          <div className={classes.dataContainer}><Text>Nationality: </Text><b>{data.nationality}</b></div>
                        </div>
                        <div className={classes.dataColumn}>
                          <div className={classes.dataContainer}><Text>Birth: </Text><b>{formattedBirthDate}</b></div>
                          {data.death ? <div className={classes.dataContainer}><Text>Death: </Text><b>{formattedDeathDate}</b></div> : null}
                          {data.date ? <div className={classes.dataContainer}><Text>Years Active: </Text><b>{data.date}</b></div> : null}
                          {data.convicted_date ? <div className={classes.dataContainer}><Text>Date Convicted: </Text><b>{data.convicted_date}</b></div> : null}
                        </div>
                        <div className={classes.dataColumn} />
                      </SimpleGrid>
                    </Grid.Col>
                  </Grid>
                </Stack>
              </Grid.Col>
            </Grid>
          </>
        )}
      </Container>
    </>
  )};
