import {Box, Text} from '@mantine/core';

import { Link } from 'react-router-dom';

import classes from './FeaturedCard.module.css';
import { ProfileCard } from '@/types';
import dateFormat from 'dateformat';

export default ({ data }: { data: ProfileCard }) => (
  <Link to={`/profile/${data.shortUrl ?? data.id}`} style={{ textDecoration: 'none' }}>
    <Box className={classes.container}>
      <img src={data.media[0]} alt={data.name} style={{ objectFit: 'cover', width: '292px', height: '220px' }} />
      <Box>
        <Text className={classes.years}>{dateFormat(data.birth, 'yyyy')}{data.death ? - dateFormat(data.death, 'yyyy') : ''}</Text>
        <Text className={classes.name}>{data.name}</Text>
      </Box>
    </Box>
  </Link>
);
