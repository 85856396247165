import { Autocomplete, ComboboxItem } from '@mantine/core';
import { useQuery } from 'react-query';
import { useState } from 'react';
import { search } from '@/queries';

import classes from './SearchAuto.module.css';

export default ({ onOptionSubmit, onChange, onKeyDown }: { onOptionSubmit: any, onChange: any, onKeyDown: any }) => {
  const [searchText, setSearchText] = useState('');
  const { isLoading, data } = useQuery([`search-${searchText}`, { w: searchText }], search);

  let profilesByShortUrl: Array<ComboboxItem> = [];

  if (!isLoading && data) {
    profilesByShortUrl = data.map(({ shortUrl, name }: { shortUrl: string, name: string }) => ({ value: shortUrl, label: name }));
  }

  return (
    <Autocomplete
      className={classes.auto}
      data={profilesByShortUrl}
      maxDropdownHeight={300}
      placeholder="Search for a profile"
      onChange={(value: string) => {
        setSearchText(value);
        onChange(value);
      }}
      onKeyDown={e => {
          if (e.key === 'Enter') {
            onKeyDown();
          }
        }
      }
      onOptionSubmit={onOptionSubmit}
      w={{ base: '100%', md: 420 }}
    />
  );
};
