import { useParams } from 'react-router-dom';
import { Space } from '@mantine/core';
import { useQuery } from 'react-query';
import Header from '@/components/Header/Header';
import HorizontalAd from '@/components/Ads/HorizontalAd';
import Footer from '@/components/Footer/Footer';
import ListProfiles from '@/components/Profiles/ListProfiles';
import { getAll } from '@/queries';

export default () => {
  const { id } = useParams();
  const { data, isLoading } = useQuery(['getAll', { id }], getAll);

  return (
    <>
      <Header />
      <HorizontalAd />
      <ListProfiles isLoading={isLoading} data={data} />
      <HorizontalAd />
      <Space h="xl" />
      <Footer />
    </>
  );
};
