import { Button, Container, Image, SimpleGrid, Skeleton, Stack, Text } from '@mantine/core';

import { FaQuoteLeft } from 'react-icons/fa';

import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import classes from './Quote.module.css';
import dateFormat from 'dateformat';

import { getQuote } from '@/queries';

export default () => {
  const { data, isLoading } = useQuery('getQuote', getQuote);

  return (
    <Container size="xl" h={{ md: '340px' }} pt="48px">
      {isLoading || !data ? <Skeleton w="100%" h="292px" /> : (
        <>
          <SimpleGrid cols={{ base: 2 }} spacing="md" visibleFrom="md">
            <Stack justify="space-between">
              <FaQuoteLeft color="red" size="40" />
              <Text className={classes.quoteText}>
                {data.quote}
              </Text>
              <Text className={classes.nameText}>
                {`${data.name}
                ${dateFormat(data.birth, 'yyyy')}${data.death ? - dateFormat(data.death, 'yyyy') : ''}
                `}
              </Text>
              <Link to={`/profile/${data.shortUrl ?? data.id}`}>
                <Button variant="default" w="125px">View Profile</Button>
              </Link>
            </Stack>
            <Image src={data.media?.[0]} alt={data.name} h="292px" style={{ maxHeight: '292px', objectFit: 'contain', objectPosition: 'top' }} />
          </SimpleGrid>
          <Stack hiddenFrom="md">
            <FaQuoteLeft color="red" size="40" />
            <Text className={classes.quoteText}>
              {data.quote}
            </Text>
            <Text className={classes.nameText}>
            <Text className={classes.years}>{dateFormat(data.birth, 'yyyy')}{data.death ? - dateFormat(data.death, 'yyyy') : ''}</Text>
            </Text>
            <Image src={data.media?.[0]} alt={data.name} h="292px" fit="cover" style={{ objectPosition: 'top' }} />
            <Link to={`/profile/${data.shortUrl ?? data.id}`} className={classes.profileButton}>
              <Button variant="default" w="100%">View Profile</Button>
            </Link>
          </Stack>
        </>
      )}
    </Container>
  );
};
