import { Container, Flex, ScrollArea, Skeleton, Text } from '@mantine/core';
import { useQuery } from 'react-query';
import FeaturedCard from '@/components/Card/FeaturedCard/FeaturedCard';

import classes from './Featured.module.css';
import { getFeatured } from '@/queries';
import { ProfileCard } from '@/types';

const Mobile = ({ data }: { data?: ProfileCard[] }) => (
  <ScrollArea w="100%" hiddenFrom="md">
    <Flex direction="row" w="100%" justify="space-between">
      {data?.slice(0, 4).map((card) => <FeaturedCard key={card.id} data={card} />)}
    </Flex>
  </ScrollArea>
);

const Desktop = ({ data }: { data?: ProfileCard[] }) => (
  <Flex direction="row" w="100%" justify="space-between" visibleFrom="md">
    {data?.slice(0, 4).map((card) => <FeaturedCard key={card.id} data={card} />)}
  </Flex>
);

export default ({ title }: { title: string }) => {
  const { data, isLoading } = useQuery('getFeatured', getFeatured);

  return (
    <Container size="xl" mt="xl">
      {isLoading || !data ? <Skeleton w="100%" h="220px" /> : (
        <>
          <Text className={classes.title}>
            {title}
          </Text>
          <Mobile data={data} />
          <Desktop data={data} />
        </>
      )}
    </Container>
  );
};
