import { Button, Container, Group } from '@mantine/core';
import { Link } from 'react-router-dom';
import classes from './ProfileButton.module.css';

const Cta = () => {
  return (
    <Container size="xl" style={{ paddingTop: '48px', align: 'center' }}>
      <Link to="/list">
        <Button variant="filled" color="red" size="xl">
          All Murderers
        </Button>
      </Link>
    </Container>
  );
};

export default Cta;
