import { Space } from '@mantine/core';
import Header from '@/components/Header/Header';
import Highlight from '@/components/Highlight/Highlight';
import HorizontalAd from '@/components/Ads/HorizontalAd';
import Featured from '@/components/Featured/Featured';
import Quote from '@/components/Quote/Quote';
import Footer from '@/components/Footer/Footer';
import ProfileButton from '@/components/Profiles/ProfileButton';

export default () => (
    <>
      <Header />
      <Highlight />
      <HorizontalAd />
      <Featured title="Featured Murderers" />
      <ProfileButton />
      <Quote />
      <HorizontalAd />
      <Space h="xl" />
      <Space h="xl" />
      <Footer />
    </>
  );
