import { Profile, ProfileCard, ProfileQuote, ProfileSearchResult, ProfilesList } from '@/types';

const { VITE_API_URL: API_URL } = import.meta.env;

export const getHighlights = async (): Promise<ProfileCard[]> => {
  const response = await fetch(`${API_URL}/profiles/highlights`);

  if (!response.ok) {
    throw new Error("Couldn't fetch highlights");
  }

  return response.json();
};

export const getAll = async (): Promise<ProfilesList[]> => {
  const response = await fetch(`${API_URL}/profiles/`);

  if (!response.ok) {
    throw new Error("Couldn't get highlights");
  }

  return response.json();
};

export const getFeatured = async (): Promise<ProfileCard[]> => {
  const response = await fetch(`${API_URL}/profiles/featured`);

  if (!response.ok) {
    throw new Error("Couldn't get highlights");
  }

  return response.json();
};

export const getProfile = async ({ queryKey }: { queryKey: any }): Promise<Profile> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, { id }] = queryKey;
  const response = await fetch(`${API_URL}/profiles/${id}`);

  if (!response.ok) {
    throw new Error("Couldn't get profile");
  }

  return response.json();
};

export const getQuote = async (): Promise<ProfileQuote> => {
  const response = await fetch(`${API_URL}/profiles/quote`);

  if (!response.ok) {
    throw new Error("Couldn't get quote");
  }

  return response.json();
};

export const search = async ({ queryKey }: { queryKey: any }): Promise<ProfileSearchResult[]> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, { w }] = queryKey;
  const response = await fetch(`${API_URL}/profiles/search?w=${w}`);

  if (!response.ok) {
    throw new Error("Couldn't search");
  }

  return response.json();
};
