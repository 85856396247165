import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import HomePage from './pages/Home.page';
import SearchPage from '@/pages/Search.page';
import ProfilePage from '@/pages/Profile.page';
import ListPage from '@/pages/List.page';
import NotFoundPage from '@/pages/Error.page';

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/list',
    element: <ListPage />,
  },
  {
    path: '/search/:search?',
    element: <SearchPage />,
  },
  {
    path: '/profile/:id',
    element: <ProfilePage />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]);

export function Router() {
  return <RouterProvider router={router} />;
}
