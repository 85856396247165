import { Space } from '@mantine/core';
import Header from '@/components/Header/Header';
import HorizontalAd from '@/components/Ads/HorizontalAd';
import Footer from '@/components/Footer/Footer';
import SearchResults from '@/components/SearchResults/SearchResults';
import Search from '@/components/Search/Search';
import Featured from '@/components/Featured/Featured';

export default () => (
    <>
      <Header />
      <Search />
      <HorizontalAd />
      <SearchResults />
      <HorizontalAd />
      <Featured title="It may interest you" />
      <Space h="xl" />
      <Footer />
    </>
  );
