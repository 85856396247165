import { Group, Burger, Container, Flex } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  FaFacebook,
  FaYoutube,
  FaTiktok,
  FaInstagram,
  FaStore,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import classes from './Header.module.css';

import Logo from '../../assets/logo.svg';

export default () => {
  return (
    <header className={classes.header}>
      <Container size="xl">
        <div className={classes.inner}>
          <Link to="/">
            <img src={Logo} alt="They will kill you" />
          </Link>
          <Group gap={5} visibleFrom="md">
            <Flex gap="md">
              <Link to="https://shop.theywillkillyou.com" target="_blank">
                <FaStore color="white" size="24px" />
              </Link>
              <Link to="https://www.facebook.com/theywillkillyoufb" target="_blank">
                <FaFacebook color="white" size="24px" />
              </Link>
              <Link to="https://www.youtube.com/@theywillkillyou" target="_blank">
                <FaYoutube color="white" size="24px" />
              </Link>
              <Link to="https://www.tiktok.com/@theywillkillyou_tt" target="_blank">
                <FaTiktok color="white" size="24px" />
              </Link>
              <Link to="https://www.instagram.com/theywillkillyou" target="_blank">
                <FaInstagram color="white" size="24px" />
              </Link>
            </Flex>
          </Group>
        </div>
      </Container>
    </header>
  );
};
